import getBrowserErrorType, {
  BrowserErrorType,
} from "@reservauto/react-shared/getBrowserErrorType";
import importRetry from "@reservauto/react-shared/importRetry";
import LocalStorageUtil from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { localeStorageKey } from "@reservauto/react-shared/stores/languageStore";
import userStore, {
  UserProfile,
} from "@reservauto/react-shared/stores/userStore";
import { User } from "oidc-client-ts";
import appUrlParams from "./appUrlParams";
import { LogService } from "./areas/general/service";
import authenticationService from "./frame/authenticationService";
import { UserMessage } from "./frame/CriticalErrorPage";
import appSettings from "./shared/appSettings";
import envSettings from "./shared/envSettings";
import branchStore from "./shared/stores/branchStore";

LocalStorageUtil.keyPrefix = "SubscriptionReact";
Logging.initialize(
  (l) => new LogService().postLog(l),
  undefined,
  envSettings.VITE_BUILDNUM,
  appSettings.Env,
  envSettings.VITE_LOGLEVELS
    ? envSettings.VITE_LOGLEVELS.toLowerCase().split(",")
    : [],
);

const currentRoute = window.location.pathname.toLowerCase();

function showCriticalErrorPage(
  exception?: unknown,
  userMessage?: UserMessage,
  user?: User | null,
): void {
  let message = userMessage;
  if (!message && exception) {
    switch (getBrowserErrorType(exception)) {
      case BrowserErrorType.cache:
        message = "BrowserCache";
        break;
      case BrowserErrorType.plugin:
        message = "BrowserPlugin";
        break;
    }
  }

  importRetry(() => import("./frame/CriticalErrorPage"))
    .then((errorPage) => errorPage.mount(exception, message, user !== null))
    .catch((newException) => {
      let logException = exception as { [name: string]: unknown };
      if (logException && typeof logException === "object") {
        logException.criticalErrorPageError = newException;
      } else {
        logException = {
          criticalErrorPageError: newException,
          error: logException,
        };
      }

      Logging.error(logException).finally(() =>
        window.navigateToFatalErrorPage(),
      );
    });
}

async function signinRedirect(user: User | null, url?: string): Promise<void> {
  if (user) {
    userStore.populate(user);
  }

  const userProfile = user?.profile as UserProfile | undefined;
  let branchId = appUrlParams.branchId ?? userProfile?.branchId ?? 1;
  branchId = typeof branchId === "string" ? parseInt(branchId) : branchId;

  branchStore.setById(branchId);
  await branchStore.populateLanguages();

  let locale = appUrlParams.locale;
  if (!locale) {
    locale = LocalStorageUtil.get(localeStorageKey, "");
  }
  if (!locale) {
    locale = window.navigator.language;
  }

  locale = branchStore.correctLocale(locale);

  authenticationService
    .signinRedirect(branchId, locale, url, undefined, appUrlParams.path)
    .catch((ex) => showCriticalErrorPage(ex, undefined, user));
}

try {
  switch (currentRoute) {
    case "/signin-callback":
      authenticationService
        .signinCallback()
        .then((newUser) => {
          userStore.populate(newUser);
          const isLocalUrl =
            newUser.state &&
            typeof newUser.state === "string" &&
            !newUser.state.includes("//");

          window.location.href = isLocalUrl ? newUser.state : "/";
        })
        .catch((ex) => {
          if (
            [
              "No matching state found in storage",
              "No state in response",
              "invalid_grant",
            ].includes(ex?.message)
          ) {
            authenticationService
              .getUser()
              .then((user) =>
                signinRedirect(user, "/").catch((e) =>
                  showCriticalErrorPage(e, undefined, user),
                ),
              )
              .catch((e) => showCriticalErrorPage(e));
          } else {
            showCriticalErrorPage(ex);
          }
        });
      break;

    case "/silent-callback":
      authenticationService
        .silentCallback()
        .catch((ex) => showCriticalErrorPage(ex));
      break;

    default:
      authenticationService
        .getUser()
        .then((user) => {
          if (!user && !appUrlParams.branchId && appSettings.Env !== "Local") {
            window.location.href = "https://communauto.com";
          } else if (!user || authenticationService.shouldRenewSession(user)) {
            signinRedirect(user).catch((ex) =>
              showCriticalErrorPage(ex, undefined, user),
            );
          } else {
            importRetry(() => import("./App"))
              .then((app) => {
                if (typeof app.mount !== "function") {
                  showCriticalErrorPage(
                    new Error("app.mount is not a function"),
                    "BrowserPlugin",
                  );
                } else {
                  app.mount(user);
                }
              })
              .catch((ex) => showCriticalErrorPage(ex, undefined, user));
          }
        })
        .catch((ex) => showCriticalErrorPage(ex));
  }
} catch (ex) {
  showCriticalErrorPage(ex);
}
